import { Box, Container, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import {
	type GroupData,
	db,
	resolveQueueNumberWithPrefix,
} from "@flowby/firebase";
import type { QueueData } from "@flowby/firebase";
import { theme } from "@flowby/general";
import { useCollData } from "../../libs/firebaseHooks";
import UpdateValueAnimation from "../shared/UpdateValueAnimation";
import CustomData from "./CustomData";
import WaitTime from "./WaitTime";

const Card = ({
	title,
	content,
	footer,
}: {
	title: string;
	content: React.ReactElement;
	footer?: React.ReactElement;
}) => {
	return (
		<Container
			p={2}
			shadow="md"
			borderWidth="1px"
			bg="white"
			borderRadius="0.375rem"
			position="relative"
		>
			<Stack alignItems="center" pb={4}>
				<Text textTransform="uppercase" fontWeight="bold" fontSize="xl">
					{title}
				</Text>
				{content}
			</Stack>
			<Box position="absolute" bottom={0} w="100%" ml={-2}>
				{footer && footer}
			</Box>
		</Container>
	);
};

export default function QueueInfo({
	store,
	queue,
	queueData,
	groupsData,
}: {
	store: string;
	queue: string;
	queueData: QueueData;
	groupsData: GroupData[] | undefined;
}) {
	const [queueNumbersData] = useCollData(db.getQueueNumbersRef(store, queue), [
		store,
		queue,
	]);
	const sortedQueueNumbersData = queueNumbersData?.sort((a, b) =>
		(a.queueNumber || 10000) > (b.queueNumber || 1000) ? 1 : -1,
	);

	const currentNumberIsManual = queueData.state.currentNumberData?.manual;
	const nextNumber = sortedQueueNumbersData?.[0]?.queueNumber;
	const nextNumberIsManual = sortedQueueNumbersData?.[0]?.manual;

	return (
		<Stack textAlign="center" alignItems="center" justifyContent="center">
			<Card
				title="Now serving"
				content={
					<>
						<Box
							position="relative"
							bgColor={currentNumberIsManual ? "blue.200" : "gray.100"}
							borderRadius="0.375rem"
							px={4}
						>
							<Text fontWeight="bold" fontSize="6xl" data-testid="queue-number">
								{resolveQueueNumberWithPrefix(
									queueData.state.currentNumber,
									queueData,
									groupsData,
								)}
							</Text>
							{currentNumberIsManual && (
								<Text
									color="gray.700"
									fontWeight="bold"
									position="absolute"
									w="100%"
									pr={8}
									align="center"
									fontSize={16}
									bottom={0}
								>
									manual
								</Text>
							)}
						</Box>

						{queueData.customDataFields && queueData.state.currentNumberID && (
							<CustomData
								store={store}
								queueData={queueData}
								queue={queue}
								currentNumberId={queueData.state.currentNumberID}
							/>
						)}
					</>
				}
			/>

			<SimpleGrid columns={2} spacing={0} w="100%" spacingX={2}>
				<Card
					title="Next up"
					content={
						<Box
							position="relative"
							bgColor={nextNumberIsManual ? "blue.200" : "gray.100"}
							borderRadius="0.375rem"
							px={2}
						>
							<Text fontWeight="bold" fontSize="4xl">
								{nextNumber !== undefined
									? resolveQueueNumberWithPrefix(
											nextNumber,
											queueData,
											groupsData,
										)
									: "-"}
							</Text>
							{nextNumberIsManual && (
								<Text
									fontWeight="bold"
									color="gray.700"
									position="absolute"
									w="100%"
									pr={4}
									align="center"
									fontSize={10}
									bottom={0}
								>
									manual
								</Text>
							)}
						</Box>
					}
					footer={
						<Stack direction="row" spacing={0} justify="center">
							<Text fontSize="sm">Waited:</Text>
							<WaitTime
								fontSize="sm"
								queueNumbersData={sortedQueueNumbersData}
							/>
						</Stack>
					}
				/>

				<Card
					title="In queue"
					content={
						<UpdateValueAnimation
							animateOnChange={[queueData.state.count]}
							defaultColor={theme.colors.colors.gray[100]}
						>
							<Box px={4}>
								<Text fontWeight="bold" fontSize="4xl">
									{queueData.state.count}
								</Text>
							</Box>
						</UpdateValueAnimation>
					}
				/>
			</SimpleGrid>
		</Stack>
	);
}
